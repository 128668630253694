// forms classes
.form_wrapper {
  border-radius: var(--radius-lg);
}
.form_name {
  font-size: 45px;
  text-align: center;
}
.form_inner_name {
  font-size: 25px;
  text-align: center;
}
.form_social_auth {
  background: var(--auto-text-white);
  border-radius: var(--radius-pill);
  color: var(--auto-text-black);
  display: flex;
  justify-content: space-between;
  button {
    font-size: 20px;
    color: var(--auto-text-black);
    text-transform: none;
    border-radius: var(--radius-pill);
    padding-left: 20px;
    padding-right: 20px;
    svg {
      margin-right: 8px;
    }
    &.form_google_auth {
      &:hover {
        background: var(--auto-text-google);
        color: var(--auto-text-white);
        svg {
          fill: var(--auto-text-white);
        }
      }
    }
    &.form_facebook_auth {
      &:hover {
        background: var(--auto-text-facebook);
        color: var(--auto-text-white);
        svg {
          fill: var(--auto-text-white);
        }
      }
    }
  }
}

.form_auth {
  $auth_font_size: 18px;
  $label_mb: 10px;
  a {
    color: var(--auto-text-white);
    text-decoration: none;
    &:hover {
      color: var(--auto-text-cyan);
    }
  }

  .form_label {
    margin-bottom: $label_mb;
    display: block;
    padding-left: 15px;
    font-size: $auth_font_size;
    .star_symbol {
      font-size: 35px;
      color: #df6145;
      vertical-align: middle;
    }
  }
  .form_control {
    display: block;
    width: 100%;
    padding: 12px 25px;
    border: none;
    font-size: $auth_font_size;
    outline: none;
    border-radius: var(--radius-pill);
    &::placeholder {
      color: var(--auto-text-black-50);
    }
  }
  
  
  select {
    color: var(--auto-text-black-50);
  }
  .form_icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3px;
    width: 60px;
    padding: 0;
    height: 1.2rem;
    background: var(--auto-text-white);
    border-radius: var(--radius-pill);
    fill: var(--auto-text-black);
    pointer-events: none;
  }

  .submit_btn {
    width: 100%;
    background: var(--auto-text-cyan);
    color: var(--auto-text-white);
    padding: 8px;
    text-transform: none;
    font-size: $auth_font_size;
    border-radius: var(--radius-pill);
    border: none;
    cursor: pointer;
    &:hover {
      background: var(--auto-text-cyan);
    }
  }
}

.form_search {
  .form_group {
    position: relative;
    .form_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 3px;
      width: 60px;
      background: var(--auto-text-white);
      border-radius: var(--radius-pill);
      fill: var(--auto-text-black);
      pointer-events: none;
    }
  }
  .select_control,
  .form_control {
    width: 100%;
    border: none;
    outline: none;
    padding: 5px 25px;
    border-radius: var(--radius-pill);
    font-size: 18px;
    font-weight: bold;
  }
  
  .select_control_v2 {
    border: none;
    outline: none;
    padding: 5px 25px;
    width: 300px;
    border-radius: var(--radius-pill);
    border: 1px solid var(--auto-text-black-50);
    font-weight: bold;
    font-size: 18px;
  }
}
.table_form {
  .form_group {
    position: relative;
    .select_control {
      width: 100%;
      border: none;
      outline: none;
      padding: 2px 20px;
      border-radius: var(--radius-pill);
      font-size: 13px;
      font-weight: bold;
      background: var(--auto-text-cf);
    }
    .form_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 3px;
      width: 60px;
      padding: 0;
      height: 1.2rem;
      background: var(--auto-text-cf);
      border-radius: var(--radius-pill);
      fill: var(--auto-text-black);
      pointer-events: none;
    }
  }
}
.form_history_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .form_group {
    display: flex;
    align-items: center;
    font-size: 14px;
    flex: 1;
    .form_label {
      white-space: nowrap;
      margin-right: 5px;
    }
    .datePicker {
      width: 100%;
      border-radius: var(--radius-pill);
      background: var(--auto-text-cf);
      border: none !important;
      outline: none;
      box-shadow: none;
      text-align: right;
      padding: 3px 12px;
    }
  }
}

.form_country_group_full_width {
  .country-select {
    width: 100%;
  }
  [data-country-code] {
    color: var(--auto-text-black);
    &:hover {
      background: var(--auto-text-danger)!important;
      color: var(--auto-text-white)!important;
    }
  }
}
// mui form helper

.form_mui_group {
  width: 100%;
  background: transparent !important;

  .form_mui_group_control {
    background: var(--auto-text-white);
    border-radius: var(--radius-pill);

    .MuiInput-root:before .MuiInput-root:after {
      display: none !important;
    }
    .MuiOutlinedInput-root {
      padding: 0px 10px;
    }
  }
  .form_mui_datepicker{
    background: var(--auto-text-white);
    border-radius: var(--radius-pill);
    width: 100%;
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        padding: 12px 25px;
      }
    }
  }
  
}

// form country code number helper
.form_country_number_control {
  padding: 6px 15px;
  border: none;
  outline: none;
  width: 100%;
  background: var(--auto-text-white);
  border-radius: var(--radius-pill);
  &.send_control_number {
    padding: 0px 4px;
  }
  > * {
    width: 100%;
    border: none;
    outline: none;
    padding: 5px 14px;
    border-radius: var(--radius-pill);
    font-size: 18px;
    background-color: var(--auto-text-white);
    font-weight: bold;
  }
  .PhoneInputInput {
    border: none;
    font-size: 18px;
    outline: none;
  }
  *::after,
  *::before {
    display: none !important;
  }
  &.sm_control {
    > * {
      padding: 5px 6px;
    }
  }
}

.formSelectCustomDropdownBox {
  position: relative;
}
