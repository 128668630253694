#BoxHead {
  .overlap_tabs {
    background: var(--auto-text-47);
    margin: auto;
    border-radius: var(--radius-pill);
    margin-top: 10px;
    .Mui-selected {
      // background: var(--auto-text-cyan);
      color: var(--auto-text-white);
      z-index: 2;
      border-radius: var(--radius-pill);
    }
    button {
      padding-inline: 33px;
    }
    .MuiTabs-indicator {
      height: 100%;
      border-radius: var(--radius-pill);
      background: var(--auto-text-cyan);
    }
  }
}

@media screen and (max-width: 640px) {
  #BoxHead {
    .overlap_tabs {
      button {
        width: 33.3%;
        padding-inline: 10px;
      }
    }
  }
}
