#AdvertisementHistory {
  #historyBox {
    padding: 25px;
    #BoxHead {
      .responsiveBoxHead {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        .form_group {
          margin-right: 2rem;
        }
      }
    }
  }

  .history_table {
    border: none;
    border: none;
    outline: none;
    box-shadow: none;
    border-top: 2px solid var(--auto-text-cyan);
    border-radius: 0 !important;
    .one_line_text {
      white-space: nowrap;
      th {
        white-space: nowrap;
        font-size: 16px !important;
      }
    }
    th {
      font-size: 20px;
      font-weight: bold;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    button,
    span {
      font-size: 18px;
    }
    td {
      font-size: 16px;
      border-bottom: 1px solid var(--auto-text-cyan);
      font-weight: normal;
      color: var(--auto-text-black);
      &.row_id {
        font-size: 25px;
        font-weight: bold;
      }
      &.text_nowrap {
        white-space: nowrap;
      }
    }
    thead tr {
      border-bottom: 2px solid var(--auto-text-cyan);
      border-top: 2px solid var(--auto-text-cyan);
    }
    thead,
    tr {
      border: none;
      outline: none;
      box-shadow: none;
      &:last-child td,
      &:last-child th {
        border-bottom: none;
      }
    }
    .history_image {
      width: 260px;
      height: 150px;
      object-fit: cover;
      object-position: center 38%;
      display: block;
      border-radius: var(--radius-lg);
    }
  }
  [class*="MuiTableContainer-root"] {
    border-radius: 0px;
    box-shadow: none !important;
  }
}
@media screen and (max-width: 640px) {
  #AdvertisementHistory {
    #historyBox {
      #BoxHead {
        border-bottom: 3px solid var(--auto-text-cyan);
        .responsiveBoxHead {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-direction: column;
          .datePicker {
            text-align: center;
          }
          .form_group {
            margin-right: 0px;
            margin-bottom: 1rem;
            width: 100%;
          }
        }
      }
    }
  }
}
