#Forgetpassword {
    form {
      background: var(--auto-text-f1);
      padding: 35px 0;
    }
    .content_wrapper {
      margin: 0px 15vw;
      background: var(--auto-text-47);
      padding: var(--content-wrapper-space);
      color: var(--auto-text-white);
      border-radius: var(--radius-lg);
    }
    .Upload_pictures_wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
    }
    .overlap_tabs {
      background: var(--auto-text-white);
      margin: auto;
      border-radius: var(--radius-pill);
      margin-top: 10px;
      width: 100%;
  
      .Mui-selected {
        // background: var(--auto-text-cyan);
        color: var(--auto-text-white);
        z-index: 2;
        border-radius: var(--radius-pill);
      }
      button {
        padding-left: 33px;
        padding-right: 33px;
        font-size: 18px;
        color: var(--auto-text-black);
      }
      .MuiTabs-indicator {
        height: 100%;
        border-radius: var(--radius-pill);
        background: var(--auto-text-cyan);
      }
    }
    .edit_profile_tab {
      max-width: 450px;
      margin: auto;
    }
  }
  
  @media screen and (max-width: 640px) {
    #EditProfile {
      .content_wrapper {
        margin: 5px;
        padding: 5px;
      }
    }
  }
  