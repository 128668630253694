#UserPanelAside {
  $left_padding: 25px;
  margin-top: 100px;
  overflow: auto;
  .content_wrapper {
    // margin-top: 84px;
    background: var(--auto-text-47);
    padding: 10px 0px;
    width: 250px;
    border-radius: 0px var(--radius-lg) var(--radius-lg) 0px;
    .Mui-expanded {
      margin: 0px !important;
      min-height: 0px !important;
    }
    .title_line {
      min-height: 0px !important;
      margin: 0px !important;
      padding: 0px;
    }
    [class*="MuiAccordionSummary-content"] {
      margin: 0px !important;
    }
    .accordion_list {
      padding-top: 8px;
      a {
        display: block;
        padding: 12px 16px;
        text-decoration: none;
        color: var(--auto-text-white);
        &:hover {
          background: var(--auto-text-cyan);
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  #UserPanelAside {
    margin-top: 0px;
    .content_wrapper {
      margin-top: 0px;
    }
  }
}
