@mixin cyan_style {
    background: var(--auto-text-cyan);
    color: var(--auto-text-white);
    border-radius: var(--radius-pill);
  }
  @mixin text_container($padding: 10px) {
    background: var(--auto-text-47);
    color: var(--auto-text-white);
    border-radius: var(--radius-lg);
    padding: $padding;
  }
  $section_padding: 40px 80px;
  
  #BuyBikes {
    padding: $section_padding;
    .BuyBike_header {
      text-align: center;
    }
    .cyan_style {
      @include cyan_style;
      display: inline-block;
      padding: 8px 65px;
    }
    section {
      .box_content {
        @include text_container($padding: 35px 10px 10px 10px);
  
        .btn_advance_search {
          color: var(--auto-text-white);
          text-transform: none;
          &:hover {
            color: var(--auto-text-cyan);
          }
        }
      }
    }
  }
  

@media screen and (max-width: 640px) {
  $section_padding: 10px 10px;
  #BuyBikes {
    padding: $section_padding;
    .cyan_style {
      padding: 8px 5px;
    }
  }
}