:root {
  --header-height: 600px;
}
#header {
  padding: 0px var(--root-space);
  position: relative;
  z-index: 3;
  .search_list_item {
    [class*="MuiMenu-list"] {
      padding: 0px !important;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: url("../../static/img/HomePage.jpg") center bottom/cover;
    width: 100%;
    height: var(--header-height);
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--auto-text-black-75);
    top: 0;
    left: 0;
  }
  .header_wrapper {
    z-index: 3;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 60px;
    .mobile_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-inline: 2rem;
      .submenubar-icon {
        font-size: 1.8rem;
        cursor: pointer;
        color: #ffdd40;
      }
      .search-button {
        min-width: 0px;
        padding: 0;
        margin: 0;
      }
    }
  }
  .menu_wrapper {
    margin-left: auto;
    .nav-menu {
      border-radius: var(--radius-pill);
      background: var(--auto-text-danger);
      display: flex;
      list-style: none;
      text-align: center;
      align-items: center;
      .nav_item {
        padding: 5px 13px;
        color: var(--auto-text-white);
        font-weight: 300;
        border-radius: var(--radius-pill);
        &:hover {
          color: var(--auto-text-danger);
          background: var(--auto-text-white);
        }
      }
    }
  }
  .search_input {
    width: 300px;
    padding: 12px;
    font-size: 23px;
    color: #d9d9d9;
  }
}

.fa-code {
  margin-left: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}
.menu_item {
  border-bottom: 2px solid var(--auto-text-cyan) !important;
}

.subMenu_list {
  background-color: #53535314;
  color: #a1a1a1;
  .subMenu_icon {
    color: #a1a1a1;
  }
}

.nav-icon {
  display: none;
}
@media screen and (max-width: 960px) {
  #header {
    padding: 0px;
    .header_wrapper {
      z-index: 3;
      position: relative;
      display: flex;
      align-items: center;
      min-height: 60px;

      .menu_wrapper {
        .nav-menu {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;
          position: absolute;
          top: 80px;
          left: -110%;
          opacity: 1;
          transition: all 0.5s ease;
          align-items: flex-start;
          .nav_item {
            font-size: 25px;
          }
        }

        .nav-menu.active {
          display: flex;
          flex-direction: column;
          left: 0px;
          opacity: 1;
          transition: all 0.5s ease;
          border-radius: 0px;
        }
        .nav-item {
          margin-block: 1rem;
        }
        .nav-item .active {
          color: #ffdd40;
          border: none;
          background-color: transparent;
        }
        .nav-links {
          padding: 1.5rem;
          width: 100%;
          display: table;
        }
      }
    }
  }
}
