/* ..............Global CSS Settings Here............ */
* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
html,
body {
  margin: 0;
  padding: 0;
  overflow: visible !important;
  padding-right: 0px !important;
}
body {
  // max-width: 1160px;
  // margin: auto;
}
a,
button {
  display: inline-block;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
:root {
  // color variable
  --auto-text-danger: #952b2f;
  --auto-text-danger-alpha: rgba(158, 52, 57, 0.9);
  --auto-text-white: #fff;
  --auto-text-dark: #2f3233;
  --auto-text-cyan: #00aeef;
  --auto-text-f1: #f1f1f1;
  --auto-text-black: #000;
  --auto-text-black-50: rgba(0, 0, 0, 0.5);
  --auto-text-black-75: rgba(0, 0, 0, 0.75);
  --auto-text-black-25: rgba(0, 0, 0, 0.25);
  --auto-text-cf: #cfcfcf;
  --auto-text-99: #999999;
  --auto-text-47: rgba(71, 72, 74, 0.9);
  --auto-text-google: #e20000;
  --auto-text-facebook: #4867aa;
  --auto-text-c7: #c7c7c7;
  --auto-text-success: #1ba132;
  // padding
  --root-space: 25px;
  --section-space: 150px;
  --section-space-sm: 60px;
  --content-wrapper-space: 10px 50px;
  // radius
  --radius-pill: 30px;
  --radius-lg: 20px;
  --radius-sm: 8px;
  --radius-circle: 50%;

  // gutter
  --gutter-xxl: 110px;

  // box shadow
}
/* ..............Global CSS Settings Here............ End*/

// global styles.............
a {
  display: inline-block;
}
// global styles.............End
// global component.............

.title_line {
  position: relative;
  &.d_inline_block {
    display: inline-block;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 100%;
    height: 3px;
    background: var(--auto-text-cyan);
    bottom: -10px;
    left: 0;
  }
  &.line_decoration {
    &::after {
      bottom: -3px;
    }
  }
}
.paragraph_space1 {
  line-height: 25px;
}
.outline-none{
  outline: none !important
}

.border-none{
  border: 0 !important
}

.px-0.px-0.px-0.px-0.px-0.px-0{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-0.py-0.py-0.py-0.py-0{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bg-white.bg-white.bg-white.bg-white.bg-white{
  background-color: white !important;
}
// global component.............End

// ..............Mui Custom Styles......
.MuiOutlinedInput-notchedOutline {
  border: none !important;
  outline: none !important;
}
.MuiAutocomplete-popper {
  li:hover {
    background: var(--auto-text-danger) !important;
    color: var(--auto-text-white);
  }
}

// ..............Mui Custom Styles......End

.container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

@media (max-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 540px;
  }
}


.capitalize.capitalize.capitalize.capitalize{
  text-transform: capitalize !important;
}

.mb-2.mb-2.mb-2{
  margin-bottom: 8px !important;
}