#SellCar2 {
  form {
    background: var(--auto-text-f1);
    padding: 35px 0;
  }
  .content_wrapper {
    margin: 0px var(--section-space);
    background: var(--auto-text-47);
    padding: var(--content-wrapper-space);
    color: var(--auto-text-white);
    border-radius: var(--radius-lg);

    .overlap_tabs {
      background: var(--auto-text-white);
      margin: auto;
      border-radius: var(--radius-pill);
      width: 100%;
  
      .Mui-selected {
        // background: var(--auto-text-cyan);
        color: var(--auto-text-white);
        z-index: 2;
        border-radius: var(--radius-pill);
        transition: color 0.25s;
      }
      button {
        padding-left: 33px;
        padding-right: 33px;
        font-size: 18px;
      }
      .MuiTabs-indicator {
        height: 100%;
        border-radius: var(--radius-pill);
        background: var(--auto-text-cyan);
      }
    }
  }
  
  .Upload_pictures_wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .Upload_picture_dropzone_wrapper{
    width: 100%;
    padding: 0px;
    .MuiDropzoneArea-root{
      background: transparent;
      border-color: white;
      border-radius: var(--radius-pill);
      padding: 1rem;
      .MuiDropzoneArea-icon{
        color: white
      }
      .MuiGrid-spacing-xs-8{
        margin: 0px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  #SellCar2 {
    .content_wrapper {
      margin: 0px 10px;
      padding: 10px 20px;
    }
  }
}
