@mixin text_container($padding: 10px) {
  background: var(--auto-text-47);
  color: var(--auto-text-white);
  border-radius: var(--radius-lg);
  padding: $padding;
}

$section_padding: 40px 80px;
#RentGarage2 {
  #section1 {
    padding: 40px 80px;
  }
  #productDetails {
    @include text_container();
    .product_title {
      display: flex;
      justify-content: space-between;
      & > * {
        margin-bottom: 10px;
        margin-top: 0px;
        position: relative;
        font-size: 35px;
        display: block;
        width: 100%;
        text-align: left;
        &::before {
          content: attr(data-before-text);
          display: block;
          position: absolute;
          bottom: -17px;
          left: 0;
          font-size: 15px;
        }
        &::after {
          content: attr(data-after-text);
          display: block;
          position: absolute;
          bottom: -17px;
          right: 0;
          font-size: 15px;
        }
      }
    }
    .product_address {
      .product_address_table {
        width: 100%;
        text-align: left;
        margin: 20px 0px;
        td {
          padding-bottom: 10px;
          .calling_btn {
            background: var(--auto-text-cyan);
            color: var(--auto-text-white);
            padding: 0px 20px;
            margin-left: 15px;
            border-radius: var(--radius-pill);
          }
        }
      }
      .buy_btn {
        background: var(--auto-text-cyan);
        color: var(--auto-text-white);
        width: 100%;
        border-radius: var(--radius-pill);
        font-size: 20px;
      }
    }
    .product_forms {
      text-align: left;
      .form_group {
        display: flex;
        margin-bottom: 10px;
        .form_label {
          min-width: 100px;
          font-size: 18px;
        }
        .form_control {
          font-size: 18px;
          background: var(--auto-text-white);
          border-radius: var(--radius-pill);
          padding: 5px 20px;
          width: 100%;

          border: none;
          outline: none;
          &::placeholder {
            color: var(--auto-text-black-50);
          }
        }
        textarea.form_control {
          border-radius: var(--radius-sm);
        }
        .form_country_number_control .PhoneInputInput {
          width: inherit;
        }
        .send_btn {
          background: var(--auto-text-cyan);
          color: var(--auto-text-white);
          border-radius: var(--radius-pill);
          padding: 3px 25px;
        }
      }
    }
  }
  #section2 {
    padding: $section_padding;

    .section_container {
      @include text_container($padding: 25px 50px);
      .overlap_tabs {
        background: var(--auto-text-white);
        margin: auto;
        border-radius: var(--radius-pill);
        margin-top: 10px;
        .Mui-selected {
          // background: var(--auto-text-cyan);
          color: var(--auto-text-white);
          z-index: 2;
          border-radius: var(--radius-pill);
        }
        button {
          $px: 80px;
          padding-left: $px;
          padding-right: $px;
        }
        .MuiTabs-indicator {
          height: 100%;
          border-radius: var(--radius-pill);
          background: var(--auto-text-cyan);
        }
      }
    }
  }
  #section3 {
    padding: $section_padding;
  }
}
@media screen and (max-width: 640px) {
  #RentGarage2 {
    #section1,
    #section2,
    #section3 {
      padding: 10px;
    }
    #section2 {
      .section_container {
        .overlap_tabs {
          button {
            $px: 30px;
            padding-left: $px;
            padding-right: $px;
          }
        }
      }
    }
  }
}
