#ContactUs {
  z-index: 2;
  max-width: 750px;
  margin: 50px auto;
  .content_wrapper {
    background: var(--auto-text-47);
    color: var(--auto-text-white);
    padding: 10px 50px;
    margin-inline: 15px;
  }
  .overlap_tabs {
    background: var(--auto-text-white);
    margin: auto;
    border-radius: var(--radius-pill);
    margin-top: 10px;
    width: 100%;

    .Mui-selected {
      background: var(--auto-text-cyan);
      color: var(--auto-text-white);
      z-index: 2;
      border-radius: var(--radius-pill);
    }
    button {
      padding-left: 33px;
      padding-right: 33px;
      font-size: 18px;
    }
    .css-1aquho2-MuiTabs-indicator {
      height: 100%;
      border-radius: var(--radius-pill);
      background: var(--auto-text-cyan);
    }
  }
  .star_symbol {
    font-size: 35px;
    color: #df6145;
    vertical-align: middle;
  }
  .term_confirm_box {
    font-size: 18px;
  }
}
