#SocialComponent {
  display: flex;
  $fb_color: #4064ac;
  $twitter_color: #1c9cea;
  $instragram_color: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);

  & > a {
    display: inline-block;
    border-radius: var(--radius-circle);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &:last-child {
      margin-right: 0px;
    }
    svg {
      fill: var(--auto-text-danger);
    }
    &:hover {
      .fb_fab {
        background: $fb_color;
        svg {
          fill: var(--auto-text-white);
        }
      }
      .twitter_fab {
        background: $twitter_color;
        svg {
          fill: var(--auto-text-white);
        }
      }
      .instragram_fab {
        background: $instragram_color;
        svg {
          fill: var(--auto-text-white);
        }
      }
    }
  }
}
