#RecoverPassword {
  max-width: 650px;
  margin: 50px auto;
  .content_wrapper {
    background: var(--auto-text-47);
    color: var(--auto-text-white);
    padding: 10px 50px;
  }
}

@media screen and (max-width: 640px) {
  #RecoverPassword {
    .content_wrapper {
      margin-inline: 15px;
    }
  }
}
