.MuiSlider-rail {
  background: var(--auto-text-white) !important;
  opacity: 1;
}

.country-select .country-list {
  width: 220px;
}

.country-select .country-list .country {
  color: var(--auto-text-black);
}
