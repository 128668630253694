#PictureUploader {
  $size: 200px;
  width: 220px;
  height: $size;
  border-radius: var(--radius-lg);
  background: var(--auto-text-cf);
  color: var(--auto-text-white);
  text-align: center;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
