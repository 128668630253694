#footer {
  z-index: 3;
  position: relative;
  .footer_content {
    background: url("../../static//img/Footer.jpg") center/cover no-repeat;
    position: relative;
    padding: 15px 50px;
    color: var(--auto-text-white);
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--auto-text-black-75);
      z-index: -1;
    }
    h4 {
      font-size: 25px;
    }
    .footer_content_top {
      text-align: center;
      .form_group {
        background: var(--auto-text-white);
        border-radius: var(--radius-pill);
        display: inline-flex;
        max-width: 100%;
        button {
          border-radius: var(--radius-pill);
          padding-left: 25px;
          padding-right: 25px;
          background: var(--auto-text-cyan);
          color: var(--auto-text-white);
        }
        input {
          padding: 8px;
          border-radius: var(--radius-pill);
          border: none;
          outline: none;
          width: 250px;
          padding-left: 25px;
          padding-right: 25px;
          &::placeholder {
            color: var(--auto-text-black-50);
          }
        }
      }
    }
    .footer_content_bottom {
      .content_title {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        align-items: center;
        & > * {
          padding: 0px 10px;
        }
        img {
          height: 50px;
        }
      }
      .content_item {
        text-align: left;
        font-size: 20px;
        h4 {
          margin-bottom: 15px;
        }
        address {
          font-style: normal;
        }
        background: transparent;
        color: var(--auto-text-white);
        a {
          display: inline-block;
          color: var(--auto-text-white);
          text-decoration: none;
          padding: 5px 0px;
          &:hover {
            color: var(--auto-text-cyan);
          }
        }
      }
    }
  }
  .footer_end_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 50px;
    color: var(--auto-text-white);
    max-width: 1200px;
    margin: auto;
    strong,
    a {
      font-size: 20px;
      color: var(--auto-text-white);
    }
  }
}

@media screen and (max-width: 960px) {
  #footer {
    .footer_content {
      .footer_content_top {
        .form_group {
          input {
            width: 170px;
          }
        }
      }

      .footer_content_bottom {
        .content_title {
          flex-direction: column;
        }
      }
    }
  }
  .footer_end_content {
    flex-direction: column;
  }
}
