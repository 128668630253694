#SocialComponent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#SocialComponent > a {
  display: inline-block;
  border-radius: var(--radius-circle);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#SocialComponent > a:last-child {
  margin-right: 0px;
}

#SocialComponent > a svg {
  fill: var(--auto-text-danger);
}

#SocialComponent > a:hover .fb_fab {
  background: #4064ac;
}

#SocialComponent > a:hover .fb_fab svg {
  fill: var(--auto-text-white);
}

#SocialComponent > a:hover .twitter_fab {
  background: #1c9cea;
}

#SocialComponent > a:hover .twitter_fab svg {
  fill: var(--auto-text-white);
}

#SocialComponent > a:hover .instragram_fab {
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), -webkit-gradient(linear, left top, left bottom, from(#6559ca), color-stop(30%, #bc318f), color-stop(50%, #e33f5f), color-stop(70%, #f77638), to(#fec66d));
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

#SocialComponent > a:hover .instragram_fab svg {
  fill: var(--auto-text-white);
}
