#CarAdvertisement2 .full_content_wrapper {
  background: var(--auto-text-f1);
}

#CarAdvertisement2 a {
  text-decoration: none;
}

#CarAdvertisement2 .box_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 145px;
  border-radius: var(--radius-lg) var(--radius-lg) var(--radius-lg) var(--radius-lg);
}

#CarAdvertisement2 .box_item .icon_box {
  width: 145px;
  height: 100%;
  background: var(--auto-text-cyan);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: var(--radius-lg) 0 0 var(--radius-lg);
}

#CarAdvertisement2 .box_item .icon_box svg {
  fill: var(--auto-text-white);
  font-size: 100px;
}

#CarAdvertisement2 .box_item .text_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 25px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  color: var(--auto-text-black);
}

#CarAdvertisement2 #section1 {
  background: var(--auto-text-f1);
  padding: var(--section-space-sm);
}

#CarAdvertisement2 .title_line {
  text-align: center;
}

#CarAdvertisement2 .package_table {
  color: var(--auto-text-black);
  width: 100%;
  text-align: left;
}

#CarAdvertisement2 .package_table td {
  padding: 8px 0px;
}

#CarAdvertisement2 .package_table td:nth-child(2) {
  width: 50%;
}

#CarAdvertisement2 .package_table .cyan_value_box {
  border-radius: var(--radius-pill);
  color: var(--auto-text-white);
  background: var(--auto-text-cyan);
  text-align: right;
  padding: 0px 10px;
  width: 100%;
}

#CarAdvertisement2 form {
  background: var(--auto-text-f1);
  padding: 35px 0;
}

#CarAdvertisement2 form .content_wrapper {
  background: var(--auto-text-47);
  color: var(--auto-text-white);
  border-radius: var(--radius-lg);
}

#CarAdvertisement2 form .Upload_pictures_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#CarAdvertisement2 form .upload_wrapper {
  border: 2px dashed var(--auto-text-white);
  padding: 20px;
  border-radius: var(--radius-lg);
}
