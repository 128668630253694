#sliderComponent {
  #slider_name {
    font-size: 35px;
    display: inline-block;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background: var(--auto-text-cyan);
      display: block;
      margin-top: 5px;
    }
  }
  .btn_view_more {
    background: var(--auto-text-cf);
    color: var(--auto-text-black);
    text-transform: none;
    font-size: 25px;
    &:hover {
      background: var(--auto-text-cyan);
      color: var(--auto-text-white);
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: var(--auto-text-white);
  }
  .slider_swiper_box {
    position: relative;
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    [class*="custom_swiper_navigate_prev"],
    [class*="custom_swiper_navigate_next"] {
      display: block;
      color: black;
    }
    [class*="custom_swiper_navigate_next"] {
      right: -50px;
    }
    [class*="custom_swiper_navigate_prev"] {
      left: -50px;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 960px) {
  #sliderComponent {
    .swiper {
      padding: 0rem;
    }
    .slider_swiper_box {
      // position: absolute;
      [class*="custom_swiper_navigate_prev"],
      [class*="custom_swiper_navigate_next"] {
        display: block;
        color: rgb(255, 255, 255);
      }
      [class*="custom_swiper_navigate_next"] {
        right: 0px;
      }
      [class*="custom_swiper_navigate_prev"] {
        left: 0px;
      }
    }
  }
}
