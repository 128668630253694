#Register {
  max-width: 650px;
  margin: 50px auto;
  .content_wrapper {
    background: var(--auto-text-47);
    color: var(--auto-text-white);
    padding: 10px 50px;
  }
  .overlap_tabs {
    background: var(--auto-text-white);
    margin: auto;
    border-radius: var(--radius-pill);
    margin-top: 10px;
    width: 100%;

    .Mui-selected {
      // background: var(--auto-text-cyan);
      color: var(--auto-text-white);
      z-index: 2;
      border-radius: var(--radius-pill);
      transition: color 0.25s;
    }
    button {
      padding-left: 33px;
      padding-right: 33px;
      font-size: 18px;
    }
    .MuiTabs-indicator {
      height: 100%;
      border-radius: var(--radius-pill);
      background: var(--auto-text-cyan);
    }
  }
  .star_symbol {
    font-size: 35px;
    color: #df6145;
    vertical-align: middle;
  }
  .term_confirm_box {
    font-size: 18px;
  }
}

@media screen and (max-width: 640px) {
  #Register {
    .content_wrapper {
      margin-inline: 15px;
      .form_social_auth {
        flex-direction: column;
        background: transparent;
        .form_google_auth {
          background: var(--auto-text-google);
          color: var(--auto-text-white);
          svg {
            fill: var(--auto-text-white);
          }
        }
        .form_facebook_auth {
          background: var(--auto-text-facebook);
          color: var(--auto-text-white);
          margin-block: 1rem;
          svg {
            fill: var(--auto-text-white);
          }
        }
      }
    }
  }
}
