#Login {
  max-width: 650px;
  margin: 50px auto;
  .content_wrapper {
    background: var(--auto-text-47);
    color: var(--auto-text-white);
    padding: 10px 50px;
    .form_social_auth {
      display: flex;
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 640px) {
  #Login {
    .content_wrapper {
      margin-inline: 15px;
      .form_social_auth {
        flex-direction: column;
        background: transparent;
        .form_google_auth {
          background: var(--auto-text-google);
          color: var(--auto-text-white);
          svg {
            fill: var(--auto-text-white);
          }
        }
        .form_facebook_auth {
          background: var(--auto-text-facebook);
          color: var(--auto-text-white);
          margin-block: 1rem;
          svg {
            fill: var(--auto-text-white);
          }
        }
      }
    }
  }
}
