#PictureUploadProfile {
    $size: 200px;
    width: $size;
    height: $size;
    border-radius: 50%;
    background: var(--auto-text-cf);
    color: var(--auto-text-white);
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin: 0px auto;
    user-select: none;
  }
  