@mixin cyan_style {
  background: var(--auto-text-cyan);
  color: var(--auto-text-white);
}

#ProductMaterialCard {
  .price_btn {
    @include cyan_style();
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1px 15px;
    font-size: 13px;
    border-radius: var(--radius-pill);
    white-space: nowrap;
  }
  #slider_name {
    font-size: 35px;
    display: inline-block;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background: var(--auto-text-cyan);
      display: block;
      margin-top: 5px;
    }
  }
  .btn_view_more {
    background: var(--auto-text-cf);
    color: var(--auto-text-black);
    text-transform: none;
    font-size: 20px;
    // height: 60px;
    .span_text {
      display: flex;
      align-items: center;
      transform: scale(1);
      transition: 0.3s;
    }
    &:hover {
      background: var(--auto-text-cyan);
      color: var(--auto-text-white);
      .span_text {
        color: var(--auto-text-white);
        transform: scale(1.2);
      }
    }
  }
  .content_info_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    span {
      background: var(--auto-text-cf);
      padding: 0px 10px;
      min-width: 45%;
      text-align: center;
      color: var(--auto-text-black);
      text-transform: uppercase;
    }
    span:nth-child(1) {
      border-radius: 0px var(--radius-pill) var(--radius-pill) 0px;
    }
    span:nth-child(2) {
      border-radius: var(--radius-pill) 0px 0px var(--radius-pill);
    }
  }

  &.ProductMaterialCard1 {
    .card_media_img {
      height: 220px;
      border: 1px solid var(--auto-text-black-50);
      overflow: hidden;
      width: 100%;
      border-radius: 15px 15px 0 0;
    }
  }
}
