.advertige_160 {
  background: var(--auto-text-c7);
  width: 160px;
  height: 600px;
  margin-left: 40px;
  color: var(--auto-text-black-50);
  font-size: 23px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
}

//

.section_title {
  font-size: 28px;
}
.section_title_lg {
  font-size: 40px;
}
.text_transform_none {
  text-transform: none;
}
.section_title_cyan {
  background: var(--auto-text-cyan) !important;
  color: var(--auto-text-white) !important;
  border-radius: var(--radius-pill) !important;
  font-size: 25px !important;
  text-align: center !important;
  padding: 8px;
  &.max_size_cyan {
    max-width: 400px;
    @media (max-width: 600px) {
      max-width: 300px;
    }
    margin: auto;
  }
}
.cf_styles_content {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: var(--auto-text-cf);
  color: var(--auto-text-black-75);
  padding: 3px;
}

.bg_img_section {
  position: relative;
  min-height: 700px;
  background: center/cover no-repeat;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    display: block;
    background: var(--bg-section-img) no-repeat center/ cover;
    z-index: -1;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    display: block;
    background: rgba(255, 255, 255, 0.9);
    z-index: -1;
  }
}

.section_space_wrapper {
  padding: 0px var(--section-space) 0 var(--section-space);
  @media screen and (max-width: 640px) {
    padding: 1rem;
  }
}
.btn_cyan_style {
  background: var(--auto-text-cyan) !important;
  color: var(--auto-text-white) !important;
  border-radius: var(--radius-pill) !important;
  text-transform: none !important;
}

@media screen and (max-width: 640px) {
  .advertige_160 {
    display: flex !important;
    width: 100% !important;
    height: 100% !important;
    margin: 40px 0px !important;
  }
}
