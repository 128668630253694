#BoxHead {
  .responsiveBoxHead {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    .form_group {
      margin-right: 2rem;
    }
  }
}
@media screen and (max-width: 640px) {
  #BoxHead {
    border-bottom: 3px solid var(--auto-text-cyan);
    .responsiveBoxHead {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      .datePicker {
        text-align: center;
      }
      .form_group {
        margin-right: 0px;
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  }
}
