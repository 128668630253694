@mixin cyan_style {
  background: var(--auto-text-cyan);
  color: var(--auto-text-white);
}

#ProductSlider {
  .favorite_active {
    color: var(--auto-text-danger);
  }
  .price_btn {
    @include cyan_style();
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1px 15px;
    font-size: 15px;
    border-radius: var(--radius-pill);
  }
  #slider_name {
    font-size: 35px;
    display: inline-block;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background: var(--auto-text-cyan);
      display: block;
      margin-top: 5px;
    }
  }
  .btn_view_more {
    background: var(--auto-text-cf);
    color: var(--auto-text-black);
    text-transform: none;
    font-size: 25px;
    &:hover {
      background: var(--auto-text-cyan);
      color: var(--auto-text-white);
    }
  }
  .content_info_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    span {
      background: var(--auto-text-cf);
      padding: 0px 10px;
      display: inline-block;
      min-width: 45%;
      text-align: center;
      color: var(--auto-text-black);
      text-transform: uppercase;
    }
    span:nth-child(1) {
      border-radius: 0px var(--radius-pill) var(--radius-pill) 0px;
    }
    span:nth-child(2) {
      border-radius: var(--radius-pill) 0px 0px var(--radius-pill);
    }
  }
  .swiper-wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .swiper_slide {
    border-radius: var(--radius-pill) var(--radius-pill) 0px 0px;
    overflow: hidden;
  }

  .slider_swiper_box {
    position: relative;
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    [class*="custom_swiper_navigate_prev"],
    [class*="custom_swiper_navigate_next"] {
      display: block;
      color: black;
    }
    [class*="custom_swiper_navigate_next"] {
      right: -50px;
    }
    [class*="custom_swiper_navigate_prev"] {
      left: -50px;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 960px) {
  #ProductSlider {
    .swiper {
      padding: 0rem;
    }
    .slider_swiper_box {
      // position: absolute;
      [class*="custom_swiper_navigate_prev"],
      [class*="custom_swiper_navigate_next"] {
        display: block;
        color: rgb(255, 255, 255);
      }
      [class*="custom_swiper_navigate_next"] {
        right: 0px;
      }
      [class*="custom_swiper_navigate_prev"] {
        left: 0px;
      }
    }
  }
}
