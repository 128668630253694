#SellBike2 {
  form {
    background: var(--auto-text-f1);
    padding: 35px 0;
  }
  .content_wrapper {
    margin: 0px var(--section-space);
    background: var(--auto-text-47);
    padding: var(--content-wrapper-space);
    color: var(--auto-text-white);
    border-radius: var(--radius-lg);
  }
  .Upload_pictures_wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 640px) {
  #SellBike2 {
    .content_wrapper {
      margin: 0px 10px;
      padding: 10px 20px;
    }
  }
}
