#homepage {
  margin-top: -380px;
  position: relative;
  z-index: 3;
  h2,
  h3 {
    font-weight: bold;
  }
  .overlap_section {
    color: var(--auto-text-white);
    //   background: var(--auto-text-f1);
  }
  .overlap_box {
    background: var(--auto-text-dark);
    width: 90%;
    min-height: 300px;
    margin: auto;
    border-radius: var(--radius-lg);
    padding: 10px;
    z-index: 2;
    position: relative;
    .overlap_tabs {
      background: var(--auto-text-white);
      margin: auto;
      border-radius: var(--radius-pill);
      margin-top: 10px;
      .Mui-selected {
        // background: var(--auto-text-cyan);
        color: var(--auto-text-white);
        z-index: 2;
        border-radius: var(--radius-pill);
        transition: color 0.25s;
      }
      button {
        padding-inline: 33px;
      }
      .MuiTabs-indicator {
        height: 100%;
        border-radius: var(--radius-pill);
        background: var(--auto-text-cyan);
      }
    }
    .form_group_wrapper {
      display: flex;
      align-items: center;
      label {
        width: 70px;
        text-align: right;
      }
      .form_group_control {
        flex: 1;
        padding: 0;
        margin-left: 10px;
        border-radius: var(--radius-pill);
        padding: 6px 12px;

        select {
          border-radius: var(--radius-pill);
          width: 100%;
          border: none;
          outline: none;
        }
      }
      .btn_search {
        width: 100%;
        background: var(--auto-text-cyan);
        border: none;
        outline: none;
        cursor: pointer;
        color: var(--auto-text-white);
        font-size: 23px;
        border-radius: var(--radius-pill);
        padding-top: 3px;
        padding-bottom: 3px;
        &:hover {
          background: var(--auto-text-white);
          color: var(--auto-text-cyan);
        }
      }
    }
  }
  #overlay_box2 {
    background: var(--auto-text-f1);
    height: 400px;
    margin-top: -185px;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 20px var(--gutter-xxl);
    pointer-events: visible;
    h5,
    h6 {
      color: var(--auto-text-black);
      transition: 0.3s;
    }
    .overlay_item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        background: var(--auto-text-cyan);
        h5,
        h6 {
          color: var(--auto-text-white);
          transform: scale(1.1);
        }
      }
    }
  }
  #section2 {
    color: var(--auto-text-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    .content_wrapper {
      background: url("../../static/img/garage_side.jpg") center/cover;
      padding: 35px var(--gutter-xxl);
      width: calc(100% - 200px);
      position: relative;
      z-index: 2;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: var(--auto-text-black-75);
        top: 0;
        left: 0;
        z-index: -1;
      }
      h2 {
        font-size: 35px;
      }
      p {
        font-size: 25px;
        font-weight: 300;
      }
      button {
        font-size: 25px;
        border-radius: var(--radius-pill);
        background: var(--auto-text-cyan);
        color: var(--auto-text-white);
        padding: 3px 18px;
        position: relative;
        margin-top: 25px;
        &::before {
          content: "";
          position: absolute;
          top: -25px;
          left: 0;
          width: 100%;
          height: 2px;
          background: var(--auto-text-cyan);
        }
        &:hover {
          background: var(--auto-text-white);
          color: var(--auto-text-cyan);
        }
      }
    }
    .homepage_ad {
      height: 500px;
      width: 150px;
      background: var(--auto-text-f1);
      color: var(--auto-text-black-50);
      font-size: 18px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding: 5px;
      text-align: center;
      font-weight: bold;
      line-height: 25px;
    }
  }
  #section3 {
    padding: 35px var(--gutter-xxl);
  }
}
@media screen and (max-width: 960px) {
  #homepage {
    margin-top: -500px;
    .overlap_box {
      width: 100%;
      .overlap_tabs {
        button {
          padding-inline: 14px;
        }
      }
    }
    #overlay_box2 {
      margin-top: 0px;
      padding: 20px;
      height: auto;
    }
    #section2 {
      flex-direction: column;
      .content_wrapper {
        padding: 20px;
        width: calc(100% - 10px);
      }
      .homepage_ad {
        height: 500px;
        width: 97%;
        background: var(--auto-text-f1);
        color: var(--auto-text-black-50);
        font-size: 25px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 5px;
        text-align: center;
        font-weight: bold;
        line-height: 40px;
      }
    }
    #section3 {
      padding: 5px;
    }
  }
}
