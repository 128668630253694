.form_wrapper {
  border-radius: var(--radius-lg);
}

.form_name {
  font-size: 45px;
  text-align: center;
}

.form_inner_name {
  font-size: 25px;
  text-align: center;
}

.form_social_auth {
  background: var(--auto-text-white);
  border-radius: var(--radius-pill);
  color: var(--auto-text-black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.form_social_auth button {
  font-size: 20px;
  color: var(--auto-text-black);
  text-transform: none;
  border-radius: var(--radius-pill);
  padding-left: 20px;
  padding-right: 20px;
}

.form_social_auth button svg {
  margin-right: 8px;
}

.form_social_auth button.form_google_auth:hover {
  background: var(--auto-text-google);
  color: var(--auto-text-white);
}

.form_social_auth button.form_google_auth:hover svg {
  fill: var(--auto-text-white);
}

.form_social_auth button.form_facebook_auth:hover {
  background: var(--auto-text-facebook);
  color: var(--auto-text-white);
}

.form_social_auth button.form_facebook_auth:hover svg {
  fill: var(--auto-text-white);
}

.form_auth a {
  color: var(--auto-text-white);
  text-decoration: none;
}

.form_auth a:hover {
  color: var(--auto-text-cyan);
}

.form_auth .form_label {
  margin-bottom: 10px;
  display: block;
  padding-left: 15px;
  font-size: 18px;
}

.form_auth .form_label .star_symbol {
  font-size: 35px;
  color: #df6145;
  vertical-align: middle;
}

.form_auth .form_control {
  display: block;
  width: 100%;
  padding: 12px 25px;
  border: none;
  font-size: 18px;
  outline: none;
  border-radius: var(--radius-pill);
}

.form_auth .form_control::-webkit-input-placeholder {
  color: var(--auto-text-black-50);
}

.form_auth .form_control:-ms-input-placeholder {
  color: var(--auto-text-black-50);
}

.form_auth .form_control::-ms-input-placeholder {
  color: var(--auto-text-black-50);
}

.form_auth .form_control::placeholder {
  color: var(--auto-text-black-50);
}

.form_auth select {
  color: var(--auto-text-black-50);
}

.form_auth .form_icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 3px;
  width: 60px;
  padding: 0;
  height: 1.2rem;
  background: var(--auto-text-white);
  border-radius: var(--radius-pill);
  fill: var(--auto-text-black);
  pointer-events: none;
}

.form_auth .submit_btn {
  width: 100%;
  background: var(--auto-text-cyan);
  color: var(--auto-text-white);
  padding: 8px;
  text-transform: none;
  font-size: 18px;
  border-radius: var(--radius-pill);
}

.form_auth .submit_btn:hover {
  background: var(--auto-text-cyan);
}

.form_search .form_group {
  position: relative;
}

.form_search .form_group .form_icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 3px;
  width: 60px;
  background: var(--auto-text-white);
  border-radius: var(--radius-pill);
  fill: var(--auto-text-black);
  pointer-events: none;
}

.form_search .select_control,
.form_search .form_control {
  width: 100%;
  border: none;
  outline: none;
  padding: 5px 25px;
  border-radius: var(--radius-pill);
  font-size: 18px;
  font-weight: bold;
}

.form_search .select_control_v2 {
  border: none;
  outline: none;
  padding: 5px 25px;
  width: 300px;
  border-radius: var(--radius-pill);
  border: 1px solid var(--auto-text-black-50);
  font-weight: bold;
  font-size: 18px;
}

.table_form .form_group {
  position: relative;
}

.table_form .form_group .select_control {
  width: 100%;
  border: none;
  outline: none;
  padding: 2px 20px;
  border-radius: var(--radius-pill);
  font-size: 13px;
  font-weight: bold;
  background: var(--auto-text-cf);
}

.table_form .form_group .form_icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 3px;
  width: 60px;
  padding: 0;
  height: 1.2rem;
  background: var(--auto-text-cf);
  border-radius: var(--radius-pill);
  fill: var(--auto-text-black);
  pointer-events: none;
}

.form_history_search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form_history_search .form_group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.form_history_search .form_group .form_label {
  white-space: nowrap;
  margin-right: 5px;
}

.form_history_search .form_group .datePicker {
  width: 100%;
  border-radius: var(--radius-pill);
  background: var(--auto-text-cf);
  border: none !important;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: right;
  padding: 3px 12px;
}

.form_country_group_full_width .country-select {
  width: 100%;
}

.form_country_group_full_width [data-country-code] {
  color: var(--auto-text-black);
}

.form_country_group_full_width [data-country-code]:hover {
  background: var(--auto-text-danger) !important;
  color: var(--auto-text-white) !important;
}

.form_mui_group {
  width: 100%;
  background: transparent !important;
}

.form_mui_group .form_mui_group_control {
  background: var(--auto-text-white);
  border-radius: var(--radius-pill);
}

.form_mui_group .form_mui_group_control .MuiInput-root:before .MuiInput-root:after {
  display: none !important;
}

.form_mui_group .form_mui_group_control .MuiOutlinedInput-root {
  padding: 0px 10px;
}

.form_country_number_control {
  padding: 6px 15px;
  border: none;
  outline: none;
  width: 100%;
  background: var(--auto-text-white);
  border-radius: var(--radius-pill);
}

.form_country_number_control.send_control_number {
  padding: 0px 4px;
}

.form_country_number_control > * {
  width: 100%;
  border: none;
  outline: none;
  padding: 5px 14px;
  border-radius: var(--radius-pill);
  font-size: 18px;
  background-color: var(--auto-text-white);
  font-weight: bold;
}

.form_country_number_control .PhoneInputInput {
  border: none;
  font-size: 18px;
  outline: none;
}

.form_country_number_control *::after,
.form_country_number_control *::before {
  display: none !important;
}

.form_country_number_control.sm_control > * {
  padding: 5px 6px;
}

.formSelectCustomDropdownBox {
  position: relative;
}
