#GarageChoosePackage {
    .full_content_wrapper {
      background: var(--auto-text-f1);
    }
    a {
      text-decoration: none;
    }
    .box_item {
      display: flex;
      height: 145px;
      border-radius: var(--radius-lg) var(--radius-lg) var(--radius-lg)
        var(--radius-lg);
  
      .icon_box {
        width: 145px;
        height: 100%;
        background: var(--auto-text-cyan);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--radius-lg) 0 0 var(--radius-lg);
        svg {
          fill: var(--auto-text-white);
          font-size: 100px;
        }
      }
      .text_box {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        flex: 1;
        color: var(--auto-text-black);
      }
    }
  
    #section1 {
      background: var(--auto-text-f1);
      padding: var(--section-space-sm);
    }
    .title_line {
      text-align: center;
      color: var(--auto-text-cyan);
    }
    .package_table {
      color: var(--auto-text-black);
      width: 100%;
      text-align: left;
      td {
        padding: 8px 0px;
        &:nth-child(2) {
          width: 50%;
        }
      }
      .cyan_value_box {
        border-radius: var(--radius-pill);
        color: var(--auto-text-white);
        background: var(--auto-text-cyan);
        text-align: right;
        padding: 0px 10px;
        width: 100%;
      }
    }
  }
  