@mixin cyan_style {
  background: var(--auto-text-cyan);
  color: var(--auto-text-white);
  border-radius: var(--radius-pill);
}
@mixin text_container($padding: 10px) {
  background: var(--auto-text-47);
  color: var(--auto-text-white);
  border-radius: var(--radius-lg);
  padding: $padding;
}
$section_padding: 40px 80px;

#RentGarage {
  #section1 {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0;
      display: block;
      background: url(../../static/img/kevin-carter-vU5_sB3jqRA-unsplash.jpg)
        no-repeat center/ cover;
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0;
      display: block;
      background: rgba(255, 255, 255, 0.55);
      z-index: -1;
    }
    .section_wrapper {
      max-width: 550px;
      margin: 0px auto;
      padding: 50px 0px;

      .Rent_Garage_header {
        text-align: center;
      }
      .cyan_style {
        @include cyan_style;
        display: inline-block;
        padding: 8px 65px;
      }
      .section_title_cyan {
        margin-top: 0px;
      }
      .content_wrapper {
        background: var(--auto-text-47);
        color: var(--auto-text-white);
        padding: 20px;
        border-radius: var(--radius-lg);
        .content_title {
          font-size: 45px;
          margin: 0;
          text-transform: uppercase;
        }
      }
    }
  }
  #section2 {
    padding: 0px var(--section-space) 0px var(--section-space);
  }
}
@media screen and (max-width: 640px) {
  $section_padding: 10px 10px;
  #RentGarage {
    #section1 {
      padding: $section_padding;
      .section_wrapper {
        .cyan_style {
          padding: 8px 5px;
        }
      }
    }
    #section2 {
      padding: 0px 15px;
    }
  }
}
