@mixin cyan_style {
  background: var(--auto-text-cyan);
  color: var(--auto-text-white);
  border-radius: var(--radius-pill);
}
@mixin text_container($padding: 10px) {
  background: var(--auto-text-47);
  color: var(--auto-text-white);
  border-radius: var(--radius-lg);
  padding: $padding;
}
$section_padding: 40px 80px;

#SellCar1 {
  .SellCar_header {
    text-align: center;
  }
  .cyan_style {
    @include cyan_style;
    display: inline-block;
    padding: 8px 15px;
  }
  #section1 {
    background: var(--auto-text-f1);
    padding: var(--section-space-sm);
  }
  .title_line {
    text-align: center;
    color: var(--auto-text-cyan);
  }
  .package_table {
    color: var(--auto-text-black);
    width: 100%;
    text-align: left;
    td {
      padding: 8px 0px;
      &:nth-child(2) {
        width: 50%;
      }
    }
    .cyan_value_box {
      border-radius: var(--radius-pill);
      color: var(--auto-text-white);
      background: var(--auto-text-cyan);
      text-align: right;
      padding: 0px 10px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 640px) {
  #SellCar1 {
    #section1 {
      background: var(--auto-text-f1);
      padding: var(--section-space-xs);
    }
  }
}
