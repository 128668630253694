#SliderThumb {
  #slider_name {
    font-size: 35px;
    display: inline-block;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background: var(--auto-text-cyan);
      display: block;
      margin-top: 5px;
    }
  }
  .btn_view_more {
    background: var(--auto-text-cf);
    color: var(--auto-text-black);
    text-transform: none;
    font-size: 25px;
    &:hover {
      background: var(--auto-text-cyan);
      color: var(--auto-text-white);
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-wrapper {
    padding: 0px 1rem;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: var(--auto-text-white);
  }
  .thumb_big_img {
    width: 100%;
    $img_size: 512px;
    height: $img_size;
    overflow: hidden;

    img {
      transition: 0.5s;
      width: 100%;
      height: $img_size;
      object-fit: cover;
      object-position: center top;
      &:hover {
        transform: scale(2);
      }
    }
  }
  @media screen and (max-width: 640px) {
    .thumb_big_img {
      $img_size: auto;
      height: $img_size;
      img {
        transition: 0.5s;
        width: 100%;
        height: $img_size;
        object-fit: cover;
        object-position: center top;
        &:hover {
          transform: scale(2);
        }
      }
    }
  }
  .SwiperItem {
    cursor: pointer;
    user-select: none;
  }

  .slider_swiper_box_thumb {
    position: relative;
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    [class*="custom_swiper_navigate_prev"],
    [class*="custom_swiper_navigate_next"] {
      display: flex;
      color: black;
    }
    [class*="custom_swiper_navigate_next"] {
      right: -0px;
    }
    [class*="custom_swiper_navigate_prev"] {
      left: -0px;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-weight: bold;
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 960px) {
  #SliderThumb {
    .swiper {
      padding: 0rem;
    }
    .swiper-wrapper {
      padding: 0px;
    }
  }
}
